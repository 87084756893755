import React from "react"
import Header from "../components/header"
//import Snippet from "../components/snippet"
import Container from "../components/container"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Status from "../components/status"

export default ({ data }) => {
  return (
    <Layout>
      <div style={{  }}>
        <Header headerText="Steve Farnworth" />
        <p style={{ fontSize: `2rem`, fontWeight: `100` }}>
          Coffee. &nbsp;&nbsp;&nbsp; Coding. &nbsp;&nbsp;&nbsp; Marketing. &nbsp;&nbsp;&nbsp; Travel. &nbsp;&nbsp;&nbsp; Video.
        </p>
        <Container>
          <Status />
          <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div key={node.id}>
              <Link
              to={node.fields.slug}>
                {node.frontmatter.title}{" "}
              </Link>
                  — {node.frontmatter.date}
              <p>{node.excerpt}</p>
              <br /><br />
            </div>            
          ))}
        </Container>
      </div>

    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`