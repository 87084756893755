import React from "react"
import { IconContext } from "react-icons";
import { FiSend } from "react-icons/fi";

export default props => (
  <IconContext.Provider value={{ className: 'react-icons' }}>
    <div id="status">
      <span class="status_header"><FiSend /> &nbsp; Building in Public</span><br />
      <strong>10 April 2020</strong> - React and Gatsby workflow set up, and basic page structure and GraphQL for Markdown files sorted.
    </div>
  </IconContext.Provider>
)